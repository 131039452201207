import {WebsiteInitialData_CachedQuery} from '@data/__generated__/types.main'
import useInitialData from '@page-components/Layout/useInitialData'
import React, {Suspense} from 'react'
import Links, {NavbarLinksSkeleton} from './Links'
import Logo, {LogoSkeleton} from './Logo'
import Menu from './Menu'

import styles from './styles.module.css'

function InnerNavbar() {
  const {website} = useInitialData()
  const navbar = website?.navbarConfiguration || {
    backgroundColor: '#fff',
    textColor: '#111',
    logo: null,
    buttonColor: null,
    links: null,
  }
  const finalLogo: WebsiteInitialData_CachedQuery['website']['logo'] = navbar.logo ?? website.logo

  const renderLogo = (logoProps?) =>
    finalLogo ? <Logo config={navbar} website={website} {...logoProps} /> : null

  return (
    <>
      <div
        className={styles.container}
        style={{backgroundColor: navbar.backgroundColor, color: navbar.textColor}}
      >
        <>
          <Menu
            pointsConfiguration={website.pointsConfiguration}
            buttonColor={navbar.buttonColor ?? '#252525'}
          />
          {renderLogo()}
          <Links links={navbar.links} categories={website.categories} />
        </>
      </div>
    </>
  )
}

function NavbarSkeleton() {
  return (
    <div className="h-[172px] w-full animate-pulse bg-gray-100 dark:bg-zinc-800">
      <LogoSkeleton />
      <NavbarLinksSkeleton />
    </div>
  )
}

export default function Navbar() {
  return (
    <Suspense fallback={<NavbarSkeleton />}>
      <InnerNavbar />
    </Suspense>
  )
}
