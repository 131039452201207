import React, {Suspense} from 'react'
import Image from '@components/Image'
import {WebsiteInitialData_CachedQuery} from '@data/__generated__/types.main'
import Link from 'next/link'

import styles from './styles.module.css'

export interface LogoProps {
  config: WebsiteInitialData_CachedQuery['website']['navbarConfiguration']
  website: WebsiteInitialData_CachedQuery['website']
  className?: string
}

function InnerLogo(props: LogoProps) {
  const {website, config, className} = props
  const {logo} = config
  const url = logo?.url

  if (!url) return null

  return (
    <div className={styles.container}>
      <div className={className}>
        <Link href="/">
          <div className={styles.imageContainer}>
            <Image
              priority
              layout="fill"
              src={url}
              objectFit="contain"
              alt={website.name || 'logo'}
              title={website.name || 'logo'}
            />
          </div>
        </Link>
      </div>
    </div>
  )
}

export function LogoSkeleton() {
  return <div className="dark:bg-zinc-800 h-[120px] w-full animate-pulse bg-gray-100" />
}

export default function Logo(props: LogoProps) {
  return (
    <Suspense fallback={<LogoSkeleton />}>
      <InnerLogo {...props} />
    </Suspense>
  )
}
